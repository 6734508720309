/* Styles for the background image */
.contact-section {
    position: relative;
    min-height: 90vh;
    overflow: hidden;
    padding: 30px;
    padding-top: 10em;
    margin-top: 5em;
    color: #fff; /* Ensure text is readable */
  }
  
  .contact-section::before {
    content: '';
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;
    height: 95%;
    background: url('../../Assets/contact.webp') no-repeat center center;
    background-size: contain;
    opacity: 0.2; /* Adjust opacity */
    z-index: -1; /* Layer behind content */
  }
  
  /* Styles for mobile devices */
  @media (max-width: 768px) {
    .contact-section::before {
        z-index: 1;
        width: 65%;
        margin-left: 4em;
        align-self: flex-end;
        height: 23%;
        background-size :contain;
        /* background-size: contain; Adjust background size for smaller screens */
        opacity: .8; /* Increase transparency for better readability on mobile */
    }
  }
  