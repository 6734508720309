/* HeadingSubtitle.css */

/* Default Styles (Desktop) */
.container-custom {
  padding-left: 200px;
  padding-right: 200px;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Space between icon and text */
}
.icon {
  font-size: 30px; /* Increase the font size to make the icon larger */
  height: 30px; /* Adjust the height if needed to control vertical size */
  width: 30px; /* Set the width to ensure proportional sizing */
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 12px;
  display: flex;
  align-items: center; /* Vertically center icon and text */
  font-size: 1.1em;
  line-height: 1.5em;
  color: #f0f0f0;
  opacity: 0; /* Initially hidden for animation */
  transform: translateY(20px); /* Initially positioned for animation */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

li.visible {
  opacity: 1;
  transform: translateY(0);
}

.service-text {
  flex: 1; /* Make the text fill the available space */
  text-align: left; /* Right-align the text */
  font-size: 1.4em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: 1.4px;
}

h1 {
  margin-bottom: 30px;
  font-size: 2.5em;
  color: #ffffff;
  text-align: center;
}

body {
  background-color: #121212;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container-custom {
    padding-left: 20px;
    padding-right: 20px;
  }

  li {
    font-size: 1em; /* Slightly smaller font for better readability */
  }

  h1 {
    font-size: 2em; /* Reduce the size of the heading */
  }
}

@media (max-width: 480px) {
  .container-custom {
    padding-left: 10px;
    padding-right: 10px;
  }

  li {
    font-size: 0.9em; /* Even smaller font size for very small screens */
    line-height: 1.4em; /* Adjust line height for readability */
  }

  h1 {
    font-size: 1.8em; /* Further reduce the heading size */
  }
}
