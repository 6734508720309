/* Base styling for the app */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Top bar styling */
.App-top-bar {
  position: fixed; /* Fixes the top bar */
  top: 0;
  left: 0;
  width: 100%;
  background-color: #282c34;
  color: white;
  padding: 10px 20px;
  z-index: 1000; /* Ensures it stays above other content */
  display: flex;
  justify-content: space-between; /* Adjust based on content */
  align-items: center;
}

/* Main content styling (below the fixed top bar) */
.App-content {
  margin-top: 60px; /* Adjust this to the height of your fixed top bar */
  padding: 20px;
}

/* Logo adjustments */
.App-logo {
  height: 40vmin;
  pointer-events: none;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  padding-top: 60px; /* Extra padding to account for the fixed top bar */
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.App-link:hover {
  color: #21a1f1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
