.project-card-view {
  margin: 20px;
  border-radius: 10px; /* Softer corners */
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.5s, box-shadow 0.5s; /* Slower transition */
  height: 100%; /* Full height */
}

.project-card-view:focus-within {
  transform: translateY(-5px); /* Effect on focus */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.project-card {
  display: flex;
  flex-wrap: wrap;
}

.project-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.project-image {
  width: 100%;
  height: auto;
  max-height: 300px; /* Fixed height */
  object-fit: contain; /* Ensures the image is not cropped */
}

.project-details-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space content evenly */
}

.card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-details {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* justify-content: space-between; */
}

.project-name {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 1px;
}

.project-subtitle {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: justify;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: auto; /* Push to bottom */
  padding-bottom: 20px;
}

.project-button {
  flex: 1;
  min-width: 150px;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
}

.project-button:hover {
  background-color: #5e2ea3; /* Adjust color for hover */
  transform: scale(1.05); /* Slight grow effect */
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.animate-ltr {
  animation-name: slide-in-left;
}

.animate-rtl {
  animation-name: slide-in-right;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .project-card-view {
    width: 90%; /* 90% of the viewport width */
    margin: 5 auto; /* Center the card horizontally */
    margin-left: 5%; /* Add 5% margin to the left */
    margin-right: 5%; /* Add 5% margin to the right */
    border-radius: 10px;
  }

  .project-name {
    font-size: 1.3rem; /* Smaller font size for mobile */
  }

  .project-subtitle {
    font-size: 1rem; /* Smaller subtitle for mobile */
  }

  .project-button {
    flex: 1 1 100%; /* Full width buttons on mobile */
    margin: 5px 0; /* Reduced margin between buttons */
  }
}
