/* Contact Form Container */
.contact-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.25); 
  padding: 2rem 1rem;
  border-radius: 12px;
  max-width: 100vh; /* Limit the max width */
  margin: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  margin-bottom: 2em;
  padding: 5em;
  padding-left: 7em;
  padding-right: 7em;
}

/* Form Header */
.contact-header {
  font-size: 2.7em;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 1.5px;
  text-decoration: underline;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.contact-subtitle {
  font-size: 1.3em;
  letter-spacing: 2px;
  margin-bottom: 3rem;
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
  color: #ffffff; /* Light gray for subtitle */
}

/* Form */
.form-animate {
  width: 100%; /* Full width */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between form elements */
}
.checkbox-view {
  margin: .4rem;
  display: flex;
}

#custom-switch {
  color: rgb(86, 85, 85) !important;
  border-width: 2px;
  border-color: #7c4dff;
  height: 20px;
  width: 40px;
  margin-right: 10px;
  text-decoration: underline;
}
.custom-switch-label {
  font-size: 20px;
  text-decoration: underline;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
}

#custom-switch:checked {
  background-color:cadetblue ;
  border-color: #7c4dff;
}

.form-input {
  width: 100%; /* Allows inputs to take full width of their container */
  background-color: #242537;
  border: none;
  padding: 1.2rem;
  border-radius: 8px;
  font-size: 1.2rem;
  transition: border 0.3s ease;
}


/* Placeholder Color */
.form-input::placeholder {
  color: #7c7c7c; /* Set your desired placeholder color */
  opacity: 1; /* Ensure opacity is 1 for better visibility */
}

.form-input:focus {
  border-color: #00AA95;
  border-bottom-width: 1px;
  border: 1px solid #00AA95; /* Accent color on focus */
}

/* When Input is Filled */
.form-input:not(:placeholder-shown) {
  background-color: #2d2f45; /* Slightly different background when filled */
  /* border: 1px solid #00AA95; Consistent accent border when filled */
  color: white;
}

/* Checkbox */
.form-check-label {
  color: #b0b3c0;
  margin-left: 0.5rem;

}

/* Submit Button */

.contact-button {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 1.5rem; /* Adjust as needed */
  width: 3000;
}

.contact-button button {
  background-color: #7c4dff;
  color: #ffffff;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button button:hover {
  background-color: #5b3cb7;
}



.contact-button button:hover {
  background-color: #5b3cb7;
}

/* Social Buttons */
.contact-social {
  margin-top: 3rem;
  text-align: center;
}

.contact-social h2 {
  font-size: 2em;
  letter-spacing: 1px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-bottom: 2rem;
}

.contact-social-btns {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.contact-social-btn {
  background-color: #7c4dff;
  color: #ffffff;
  padding: 0.6rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.contact-social-btn:hover {
  background-color: #5b3cb7;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .contact-content {
    padding: 1.5rem;
  }
  .row {
    flex-direction: column; /* Stack inputs */
    gap: 1rem; /* Consistent spacing */    
  }
  .form-animate {
    flex-direction: column; /* Stack inputs */
    gap: 1rem; /* Consistent spacing */
  }

  .contact-button {
    justify-content: center; /* Center button on mobile */
  }
  .form-input {
    padding: .7rem;
  }
}

/* New Responsive Rule for Screens Less Than 350px */
@media (max-width: 350px) {
  .contact-social-btns {
    flex-direction: column; /* Stack buttons vertically */
    gap: 1rem; /* Consistent spacing */
  }
  .contact-social-btn {
    justify-content: center;
    gap: 1rem;
  }
}
