
/* Resume Section Container with Animation */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

/* Resume Section Container */
.resume-section {
    padding: 16px;
  }
  
  /* Centered Download Button Styling */
  .download-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 16px;
  }
  
  /* Download Button Styles */
  .download-button {
    width: 60%;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s;
    margin-left: 2em; /* Remove additional horizontal margins */
    margin-right: 2em; /* Remove additional horizontal margins */
  }
  
  /* Hover Effect for Download Button */
  .download-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-3px);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .resume-section {
      padding: 12px;
    }
    .download-button-container {
        width: 65%;
        justify-content: center;
        align-items: center;
        align-self: center;
   
    }
    .download-button {
      font-size: 15px;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 576px) {
    .resume-section {
      padding: 8px;
    }
  
    .download-button {
      font-size: 14px;
      padding: 6px 12px;
    }
    .download-button-container {
        width: 100%;
        justify-content: center;
        align-items: center;
        align-self: center;
   
    }
  }
  
  /* Centered Document Styling */
  .resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeInUp 0.8s ease-out 0.5s; /* Animation with a delay */
  }
  