.project-section {
    padding: 20px; /* Add padding around the project section */
  }
  
  .project-heading {
    font-size: 32px; /* Heading font size */
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .project-heading {
      font-size: 24px; /* Smaller heading font size for mobile */
    }
  
    .project-section {
      padding: 15px; /* Reduced padding for mobile */
    }
  }